import React, { useContext, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFooterData } from '../../redux/slices/footerSlice';
import toast from 'react-hot-toast';
import { TranslateContext } from '../../context/TranslateContext';

function FooterBanner() {
  let { footerData, error } = useSelector(({ footerData }) => footerData);
  const { arLang } = useContext(TranslateContext);
  let dispatch = useDispatch()
  useEffect(() => {
    
    if(error){
      toast.error(error);
    }
  }, [])
  useEffect(() => {
    
    if (error) {
      toast.error(error);
    }
  }, [arLang])
  return <>
    <section dir='ltr' className='py-16'>
      <div className="container">
        <div className='flex flex-wrap items-center overflow-hidden banner rounded-3xl p-6'>
          <div className='w-full px-4 my-4 md:w-1/2'>
            <Fade direction='up'>
              <div className='porse'>
                <h2 className='font-Poppins text-white font-semibold text-[32px]'>
                 {footerData?.Intro?.title}
                </h2>
                <p className='font-Poppins mt-3 text-[#E9ECF2]'>
                {footerData?.Intro?.overview}
                </p>
                {/* <button className='border-0 px-1 mt-7 rounded-xl group py-1 outline-0 bg-gradient-to-r from-[#F90606] to-[#CE5D0C] text-white'>
                  <div className='flex items-center justify-center w-full'>
                    <span className='font-Poppins font-semibold text-[18px]'>Explore</span>
                    <span className='text-white p-2'><FaArrowRight /></span>
                  </div>
                </button> */}
              </div>
            </Fade>
          </div>
          <div className='w-full px-4 my-4 md:w-1/2'>
            <Fade direction='right'>
              <div className='rounded-xl overflow-hidden'>
                <iframe width="100%" height="415" src={footerData?.Intro?.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default FooterBanner;