import React, { useContext, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import { ReactLenis } from 'lenis/react'
import { TranslateContext } from '../../context/TranslateContext';
import { menuContext } from '../../context/SideMenuContext';
import Loading from '../Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import Test from '../test';
import { fetchFooterData } from '../../redux/slices/footerSlice';
function Layout() {
  const { arLang } = useContext(TranslateContext);
  const { isMenuOpen } = useContext(menuContext);
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.footerData)
  console.log('isLoading: ', isLoading);
  useEffect(() => {
    dispatch(fetchFooterData())
  },[])
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isMenuOpen])
  return isLoading ? <Loading /> : <>
  <ReactLenis root>
    <div dir={`${arLang ? "rtl" : 'ltr'}`}>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  </ReactLenis>
</>
}

export default Layout;